<template>
  <div class="van-nav-bar refresh-nav-bar">
    <van-nav-bar title="" left-text="" right-text="AI对话助手" v-if="!isShowAibtn">
      <template v-slot:right>
        <div class="Ai" @click="handlerAi">
          <img src="@/assets/Ai.png" alt="">
          <div>AI对话助手</div>
        </div>
      </template>
    </van-nav-bar>
    <div v-if="isShowAibtn" class="AiPopUp">
      <div class="image">
        <img  src="@/assets/Ai.png" alt="">
      </div>
      <div class="ai-chat-container">
        <div class="chat-header">
          <van-icon name="cross" @click="closeAi" />
        </div>
        <div class="chat-title">AI对话助手</div>
        <div class="chat-messages" ref="scrollContainer" @scroll="handleUserScroll">
          <div style="text-align: center;" v-if="isShowLoading">
            <van-loading color="#1989fa" />
          </div>
          <div v-for="(item,index) in list" :key="index">
            <div class="deepThinking" @click="getSwitch(item)" v-if="item.position == 'left'">
              {{item.isLoading?'开始思考':'已深度思考'}}
              <van-icon name="arrow-down" v-if="!item.use_history" />
              <van-icon name="arrow-up" v-if="item.use_history" />
            </div>
            <div class="message-left" v-if="item.position == 'left'">
              <div v-if="item.think_obj.length==0&&item.isAbnormal">服务器繁忙，请稍后再试</div>
              <!-- position位置  use_history是否展开 content：分析 result：结论  -->
              <div class="message-content" v-if="item.think_obj.length!=0&&!item.isAbnormal">
                 <!-- 分析 -->
                <div class="tinkClass" v-if="item.use_history">
                  <span v-for="(val, i) in item.think_obj" :key="i">
                    <span class=" sse-text">{{ val.message }}</span>
                  </span>
                </div>
                <!-- 结论 -->
                <div>
                  <span v-for="(val2, i2) in item.answer_obj" :key="i2">
                    <span class="sse-text">{{ val2.message }}</span>
                  </span>
                </div>
              </div>
              <van-loading v-if="item.isLoading" />
            </div>
            <div class="message-right" v-if="item.position=='right'">
              <div class="message-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="send_btn">
          <div class="chat-input">
            <van-field type="textarea" :disabled="disabledInput" autosize v-model="problem.content" placeholder="请输入问题" :border="false"
              @keyup.enter="sendMessage" />
            <div class="chat-input-bott">
              <span class="tip">{{ problem.content.length }}/1000</span>
              <van-button type="primary" size="small" @click="sendMessage"
                :disabled="!problem.content.trim()" v-if="!disabledInput">发送</van-button>
                <van-button type="primary" size="small" v-if="disabledInput" @click="sendEnd">结束</van-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  qyChatRequest,qyChatAssistantList
} from '@/api/test'
import env from '../../env';
export default {
  props:["isShowAibtn",'source','qy_out_user_id','url'],
  data() {
    return {
      total: 0,
      cpage: 1,
      isShowLoading: false,
      problem:{
        content: '',
        position:"right"
      },
      pram:{
        user_id:JSON.parse(localStorage.getItem('userInfo')).user_id,
        qy_ous_user_id:JSON.parse(localStorage.getItem('userInfo')).qy_ous_user_id
      },
      
      list:[],
      answerList:{
          // 分析
          think_obj:[],
          // 结论
          answer_obj:[],
          // 位置
          position:'left',
          // 是否展开
          use_history:true,
          time:0,
          isLoading:false
        },
        disabledInput:false,//sse执行中禁止输入
        startTime: null, // 记录 SSE 开始时间
        endTime: null, // 记录 SSE 结束时间
        isUserScrolling:false,
        answerListNew:{}
    }
  },

  methods: {
    handlerAi() {
      this.$emit('handlerAi',true)
      this.scrollToBottom()
      this.cpage = 1
      this.getShow(this.cpage);
    },
    closeAi() {
      this.$emit('handlerAi',false)
      this.sendEnd()

    },
    getSwitch(item){
      console.log(item.use_history,'..item.use_history')
      item.use_history=!item.use_history
      this.$forceUpdate()
      console.log(item,222)
    },
    async sendMessage() {
      if (this.problem.content.trim()) {
        let problembackups=JSON.parse(JSON.stringify(this.problem))
        console.log(problembackups,'..problembackups')
        this.list.push(problembackups)
        console.log(this.list,'..this.list')
        this.problem.content = ''
        this.answerListNew=JSON.parse(JSON.stringify(this.answerList))
        console.log(this.pram,'..this.pram')
        this.scrollToBottom()
        this.disabledInput=true
        let res = await qyChatRequest({...this.pram,message:problembackups.content})
        if(res.code==200){
          this.answerListNew.isLoading=true
      
          this.initSSE();
        }
      }
    },
    beforeDestroy() {
      if (this.eventSource) {
        this.eventSource.close();
      }
    },
      // 滚动到页面底部
    scrollToBottom() {
      if (!this.isUserScrolling) {
        this.$nextTick(() => {
          // 等待 DOM 更新后再滚动
          const container = this.$refs.scrollContainer;
          container.scrollTop = container.scrollHeight;
        });
      }
    },
    // 用户自动滚
    handleUserScroll () {
      const container = this.$refs.scrollContainer;
      // 当用户滚动到上方一定距离（例如 50px 以上），则停止自动滚动
      if (container.scrollTop + container.clientHeight < container.scrollHeight - 50) {
        this.isUserScrolling = true;
      } else {
        this.isUserScrolling = false;
      }
      console.log(container.scrollTop,'..container.scrollTop')
      // 往上滑触顶翻页
        if (container.scrollTop === 0) {
        console.log(this.total, this.list.length)
        if (this.total > this.list.length) {
          this.cpage++
          console.log(this.cpage,'..this.cpage')
          this.isShowLoading = true
          this.getShow(this.cpage);
          console.log(this.cpage, '..this.cpage')
        }

        console.log('加载更多');
      }
      // console.log(scrollTop,'..scrollTop')
    },
    async getShow(cpage) {
      let res = await qyChatAssistantList({
        user_id: this.pram.user_id,
        page: cpage,
      });

      if (res.code == 200) {
        console.log(cpage, this.list, '..cpage')
        this.isShowLoading = false
        if (cpage == 1) {
          this.list = res.data.data
        } else {
          this.list = this.mergeAndDeduplicate(res.data.data, this.list)
        }
        this.total = res.data.total,
          console.log(this.list, '..this.list')
      }
    },
    // 合并去重
    mergeAndDeduplicate(array1, array2) {
      // 使用 Map 存储最终结果，以 array1 的项优先
      const map = new Map();

      // 先遍历 array1，优先保留它的项
      array1.forEach(item => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });

      // 再遍历 array2，仅添加未出现在 array1 中的项
      array2.forEach(item => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });

      // 按顺序返回最终结果
      return Array.from(map.values());
    },
    // sse
    initSSE() {
      
      this.startTime = Date.now(); // 记录开始时间
      this.list.push(this.answerListNew)
      this.eventSource = new EventSource(`${env.baseUrl}/api/v1/wechat/qy/${this.url}?user_id=${this.pram.user_id}&qy_ous_user_id=${this.pram.qy_ous_user_id}&source=${this.source}&qy_out_user_id=${this.qy_out_user_id}`);
      console.log(this.list,'..this.list')
      
      this.eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if(data.type=='think'){
          this.list[this.list.length - 1].think_obj.push(data)
        }else if(data.type=='answer'){
          this.list[this.list.length - 1].answer_obj.push(data)
        }
        this.scrollToBottom()
      };

      this.eventSource.onerror = () => {
        if(this.list[this.list.length - 1].think_obj.length==0){
          this.list[this.list.length - 1].isAbnormal=true
        }else{
          this.list[this.list.length - 1].isAbnormal=false
        }
        this.sendEnd()
      };
    },
  
    // 关闭sse
    sendEnd(){
      this.list[this.list.length - 1].isLoading=false
      this.eventSource.close();
      this.disabledInput=false
        // 更新结束时间
      this.endTime = Date.now();
      this.list[this.list.length - 1].time= ((this.endTime - this.startTime) / 1000).toFixed(2); // 转换成秒
    }
  }
}
</script>

<style lang="less" scoped>
.van-nav-bar {
  position: sticky;
  text-align: justify;
  top: 0;
  user-select:unset !important;
}

.Ai {
  width: 116px;
  height: 34px;
  background: inherit;
  background-color: rgba(44, 140, 240, 1);
  border-radius: 28px 0 0 28px;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 34px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  img{
    width: 24px;
    height: 24px;
  }
}

::v-deep .van-nav-bar__right {
  padding: 0;
  box-sizing: border-box;
}

.AiPopUp {
  background: linear-gradient(180deg, #3d9af9 0%, #f4f5f6 30%);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  height: 60vh;
  z-index: 999;
  position: relative;
  .image{
    position: absolute;
    left: 36px;
    top: -4px;
    width: 76px;
    height: 76px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  .ai-chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .chat-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  .chat-title{
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    color: #fff;
    width: 100%;
  }

  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 20px 20px 0 0;
    background: #fff;

    .message-left,
    .message-right {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }

    .message-right {
      align-items: flex-end;
    }

    .message-content {
      max-width: 100%;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 1.5;
      word-break: break-word;
      margin-bottom: 10px;
    }

    .message-left .message-content {
      background-color: #f5f5f5;
      color: #333;
    }

    .message-right .message-content {
      background-color: #2C8CF0;
      color: #fff;
    }
  }

  .send_btn{
    background: #fff;
    border-radius:0  0 20px 20px;
  }
  .chat-input {
    // display: flex;
    // align-items: center;
    border-radius:20px ;
    border: 1px solid #3d9af9;
    overflow: hidden;

    .van-field {
      flex: 1;
      margin-right: 10px;

      ::v-deep .van-field__control {
        border: none;
        padding: 0;
      }
    }

    
  }
}
.chat-input-bott{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding:0 10px 10px;
  box-sizing: border-box;
  .tip{
    color: #999999;
    margin-right: 10px;
  }
}
.sse-text {
  white-space: pre-line;
}
.tinkClass{
  color: #999;
  border-left: 1px solid #999;
  padding-left: 10px;
  box-sizing: border-box;
}
.deepThinking{
  padding:5px 10px;
  box-sizing: border-box;
  background: #ededed;
  border-radius: 16px;
  margin-bottom: 10px;
  width: 40%;
  color: #262626;
  cursor: pointer;
}
</style>