<template>
  <div id="main-content" class="main-content">
    <AIAssistant :class="isShowAibtn?'AIAssistant':''" url="chat_assistant_anser" :qy_out_user_id="qy_out_user_id"  source="user_chat" :isShowAibtn="isShowAibtn" @handlerAi="handlerAi" />
    <div :class="isShowAibtn?'AIstyle':''">
      <Refresh />
      <div class="content-list">
        <div style="text-align: center;" v-if="isShowLoading">
          <van-loading color="#1989fa" />
          <!-- <van-loading size="24px" color="#1989fa" vertical>加载中...</van-loading> -->
        </div>

        <div @scroll="handleScroll" ref="scrollContainer" class="scroll-container chat-box">
          <div class="moreNull" v-if="total == chat_list.length">暂无更多数据</div>
          <template v-if="chat_list">
            <div v-for="item in chat_list" :key="item.id">
              <div :class="['msgtime', item.position == 'left' ? 'msgtime-left' : 'msgtime-right']">{{ item.msgtime }}
              </div>
              <div :class="['chat-box-item', item.position == 'left' ? 'chat-box-left' : 'chat-box-right Aichat-box-right']">
                <div class="content">
                  <div v-if="item.msgtype === 'text'">
                    <!-- 显示文本消息 -->
                    <div class="message-text">
                      <!-- <p>{{ item.text }}11</p> -->
                      <span v-for="(val, i3) in item.text" :key="i3" class="message-text">
                          <span class=" sse-text">{{ val.message }}</span>
                        </span>
                    </div>
                  </div>

                  <div v-else-if="item.msgtype === 'image'">
                    <!-- 显示图片消息 -->
                    <div class="message-image">
                      <img :src="item.url" alt="Image message">
                    </div>
                  </div>

                  <div v-else-if="item.msgtype === 'video'">
                    <!-- 显示视频消息 -->
                    <div class="message-video">
                      <video :src="item.url" controls></video>
                    </div>
                  </div>

                  <div v-else>
                    <!-- 显示默认消息或未知消息类型 -->
                    <div class="message-default">
                      <p>Unknown message type</p>
                    </div>
                  </div>

                </div>
                <!-- 按钮 -->
                <div class="operate" v-if="item.position == 'left'">
                  <span class="send" @click="isDisable?'':AIResponse(item.id)">AI答复</span>
                </div>
                <div class="operate operateRight" v-if="item.position == 'right'">
                  <span class="send" @click="send(item.content)">发送</span>
                </div>
              </div>
              <!-- <div>{{ item.ai_answer }}</div> -->
              <!-- Ai答案 -->
              <div v-for="(item2, index) in item.ai_answer" :key="index">
                <div class="msgtime msgtime-right">{{ item2.created_at }}</div>
                <div class="chat-box-item  chat-box-right chat-box-rightAi">
                  <div class="operate Aioperate">
                    <div>AI答复结果</div>
                    <div class="deepThinking" >  {{item2.isLoading?'开始思考':'已深度思考'}}</div>
                  </div>
                  <div v-if="item2.think_obj.length==0&&item2.isAbnormal">服务器繁忙，请稍后再试</div>
                  <div v-if="item2.think_obj.length!=0&&!item2.isAbnormal">
                    <div class="content content-right">
                      <!-- <div class="message-text thinking" v-if="item2.use_history">
                    <p>{{ item2.thinking }}</p>
                  </div> -->
                      <div class="message-text thinking" v-if="item2.use_history">
                        <span v-for="(val, i) in item2.think_obj" :key="i">
                          <span class=" sse-text">{{ val.message }}</span>
                        </span>
                      </div>
                      <!-- 显示文本消息 -->
                      <!-- <div class="message-text message-sse" v-if="!item2.isShowEdit">
                    <p v-for="val in item2.content" :key="val">{{ val.message }}</p>
                  </div> -->
                  <!-- 编辑 -->
                      <div v-if="!item2.isShowEdit" class="message-text message-sse">
                        <span v-for="(val2, i2) in item2.answer_obj" :key="i2" >
                          <span class="sse-text">{{ val2.message }}</span>
                        </span>
                      </div>
                      <van-field type="textarea" v-if="item2.isShowEdit" autosize v-model="item2.answer_text"
                        placeholder="请输入问题" @keyup.enter="sendMessage" />
                    </div>
                    <!-- 按钮 -->
                    <div style="display: flex; align-items: center;justify-content: end;">
                      <template v-if="!item2.isShowEdit">
                        <template v-if="item2.isLoading" >
                          <div class="operate" style="margin-right: 10px;cursor: pointer;" >
                          <span class="send" @click="sendEnd(item.id)">结束</span>
                        </div>
                          <van-loading />
                        </template>
                        
                        <!-- 按钮 -->
                        <template v-if="!item2.isLoading" >
                          <div class="operate" style="margin-right: 10px;cursor: pointer;" v-if="item2.think_obj">
                          <span @click="getSwitch(item2)" >{{item2.use_history?'关闭':'展开'}}分析</span>
                        </div>
                        <!-- 点赞 -->
                        <div class="operate editImg" >
                          <img @click="perception(item2,0)" v-if="item2.like_status==1" src="@/assets/up_full.png" alt="Image message">
                          <img  @click="perception(item2,1)"  v-else src="@/assets/up_empty.png" alt="Image message">
                        </div>
                        <!-- 踩 -->
                        <div class="operate editImg">
                          <img  @click="perception(item2,0)" v-if="item2.like_status==2" src="@/assets/down_full.png" alt="Image message">
                          <img  @click="perception(item2,2)" v-else src="@/assets/down_empty.png" alt="Image message">
                        </div>
                         <!--编辑-->
                        <div class="operate editImg" @click="Edit(item2)">
                          <img src="@/assets/edit.png" alt="Image message">
                        </div>
                        <div class="operate">
                          <span class="send" @click="send(item2.answer_text)">发送</span>
                        </div>
                        </template>
                      </template>
                      <!-- 编辑按钮 -->
                      <template v-if="item2.isShowEdit">
                        <div class="operate" @click="close(item2)">
                          <span>取消</span>
                        </div>
                        <div class="operate">
                          <span class="send" @click="sumbit(item2)">确认</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!--      <div class="memeberPhone" @click="handlePhone">-->
        <!--        <image src="/img/memeberPhone.png"></image>-->
        <!--      </div>-->

      </div>
    </div>
  </div>
</template>
<script>

import {
  chatList, aiSearch, pollingTime,qyAnswerLike,qyAnswerUpdate
} from '@/api/test'
import { checkBind, checkLoadWxConfig, clearCacheData, getUserInfo, setEnterPath } from "../../utils/base";
import Refresh from "@/components/home/refresh.vue";
import { Toast } from "vant";
import env from "../../../env";
import AIAssistant from "@/components/aIAssistant.vue";

const wx = window.wx;

export default {
  components: {
    Refresh,
    AIAssistant
  },
  data() {
    return {
      cpage: 1,
      chat_list: [],
      total: 0,
      isShowLoading: false,
      isShowAibtn:false,
      isDisable:false,
      answerList:{
          // 分析
          think_obj:[],
          // 结论
          answer_obj:[],
          // 位置
          position:'right',
          // 是否展开
          use_history:true,
          time:0,
          isLoading:false,
          answer_text:''
        },
        answerListNew:{},
        list:{
          ai_answer:[],
          id:'',
          msgtype: "text",
          position: "",
          text:[]
        },
        listNew:{},
        qy_ous_user_id:JSON.parse(localStorage.getItem('userInfo')).qy_ous_user_id,
        qy_out_user_id:localStorage.getItem('qy_out_user_id')
    }
  },
  async mounted() {

    console.log('chat list');

    // setEnterPath('/');

    // 判断绑定
    if (!checkBind(this.$router)) {
      return false;
    }

    // 判断初始化
    if (!checkLoadWxConfig(this.$router)) {
      return false;
    }

    this.cpage = 1
    // 获取数据
    await this.getShow(this.cpage);
    this.scrollToBottom();
   
    this.initSSEList()
    // this.pollingTimeFn()
    // this.time = setInterval(() => {
    //   this.scrollToBottom();
    //   this.cpage=1
    //   this.getShow(this.cpage);
    // }, 10000)

  },
  methods: {
    // async pollingTimeFn() {
    //   let res = await pollingTime()
    //   let that = this
    //   if (res.code == 200) {
    //     this.time = setInterval(() => {
    //       that.scrollToBottom();
    //       that.cpage = 1
    //       that.getShow(this.cpage);
    //       console.log('轮询')
    //     }, res.data)
    //   }
    // },
    handleScroll: _.debounce(function (event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 往上滑触顶翻页
      if (scrollTop === 0) {
        // this.loadMore();
        console.log(this.total, this.chat_list.length)
        if (this.total > this.chat_list.length) {
          this.cpage++
          // this.$refs.scrollContainer.scrollTop = 0;
          this.isShowLoading = true
          this.getShow(this.cpage);
          console.log(this.cpage, '..this.cpage')
        }

        console.log('加载更多');
      }
    }, 200),
    // 滚动到页面底部
    scrollToBottom() {
      let container = this.$refs.scrollContainer;
      container.scrollTop = container.scrollHeight;
      console.log(container.scrollTop, container.scrollHeight)
    },
    getUserId() {
      let userInfo = getUserInfo();
      let qy_out_user_id = userInfo.qy_out_user_id;
      return qy_out_user_id;
    },

    async getShow(cpage) {
      let qy_out_user_id = this.getUserId();
      let res = await chatList({
        qy_out_user_id: qy_out_user_id,
        // qy_out_user_id: 'GaoLiCheng',
        page: cpage,

      });

      if (res.code == 200) {
        console.log(cpage, this.chat_list, '..cpage')
        this.isShowLoading = false
        // 合并
        //  const mergedArray = cpage==1?res.data.data:res.data.data.concat(this.chat_list);
        // // 根据 id 去重
        // this.chat_list = mergedArray.filter(
        //   (item, index, self) => self.findIndex(i => i.id === item.id) === index
        // );
        if (cpage == 1) {
          this.chat_list = res.data.data
        } else {
          this.chat_list = this.mergeAndDeduplicate(res.data.data, this.chat_list)
        }
        this.total = res.data.total,
          console.log(this.chat_list, '..this.chat_list')
      }
    },
    // 合并去重
    mergeAndDeduplicate(array1, array2) {
      // 使用 Map 存储最终结果，以 array1 的项优先
      const map = new Map();

      // 先遍历 array1，优先保留它的项
      array1.forEach(item => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });

      // 再遍历 array2，仅添加未出现在 array1 中的项
      array2.forEach(item => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });

      // 按顺序返回最终结果
      return Array.from(map.values());
    },
    getSwitch(item){
      console.log(item.use_history,'..item.use_history')
      item.use_history=!item.use_history
      this.$forceUpdate()
      console.log(item,222)
    },

    send(msg) {
      console.log(msg)
      // console.log(order,orderId,'miniprogram')
      wx.invoke('sendChatMessage', {
        msgtype: 'text',
        enterChat: true, // 是否跳转到聊天界面
        text: {
          content: msg
        }
      }, function (res) {
        if (res.err_msg == 'sendChatMessage:ok') {
          //发送成功
        }
      })
    },
    // 编辑
    Edit(item){
      item.isShowEdit=true
      this.$forceUpdate()
    },
    // 点赞与下踩
  async  perception(item,status){
      item.like_status=status
      this.$forceUpdate()
      let res=await qyAnswerLike({id:item.id,like_status:status})
      if(res.code==200){

      }
      console.log(item,'.item')
    },
    close(item){
      item.isShowEdit=false
      this.$forceUpdate()
    },
   async sumbit(item){
      item.answer_obj=[{message:item.answer_text,type:"answer"}]
      let res=await qyAnswerUpdate({id:item.id,content:item.answer_text})
      if(res.code==200){
        item.isShowEdit=false
      }
      this.$forceUpdate()
      console.log(item,'.item')
    },
    // Ai答复
    async AIResponse(id) {
      this.answerListNew=JSON.parse(JSON.stringify(this.answerList))
      this.answerListNew.isLoading=true
      this.isDisable=true
      this.initSSE(id);
    },
    handlerAi(isShow){
      console.log(isShow,'..isShow')
      this.isShowAibtn=isShow
      this.$forceUpdate()
    },
    // sse
    initSSE(id) {
      this.chat_list.forEach(val => {
        if (val.id == id) {
          val.ai_answer.unshift(this.answerListNew)
        }
      })
      console.log(this.chat_list,id,'this.chat_list')
      this.eventSource1 = new EventSource(`${env.baseUrl}/api/v1/wechat/qy/chat_ai_anser?qy_out_user_id=${this.getUserId()}&id=${id}&qy_ous_user_id=${this.qy_ous_user_id}`);
      
      this.eventSource1.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data)
        this.chat_list.forEach(val => {
          if (val.id == id) {
            if (data.type == 'think') {
              val.ai_answer[0].think_obj.push(data)
            } else if (data.type == 'answer') {
              val.ai_answer[0].answer_obj.push(data)
            }
            val.ai_answer[0].answer_text=val.ai_answer[0].answer_obj.map(item => item.message).join('');
          }
        })
        console.log(this.chat_list,'..this.chat_list')
      };
      this.eventSource1.onerror = () => {
        this.chat_list.forEach(val => {
          if (val.id == id) {
            if (val.ai_answer[0].think_obj.length == 0) {
              val.ai_answer[0].isAbnormal = true
            } else {
              val.ai_answer[0].isAbnormal = false
            }
          }
          
        })
        console.log(11111)
        this.sendEnd(id)
      };
    },
    // 列表sse（页面一进来就启动）
    initSSEList(){
      this.eventSource2 = new EventSource(`${env.baseUrl}/api/v1/wechat/qy/chat_pull?qy_out_user_id=${this.getUserId()}&source=user&qy_ous_user_id=${this.qy_ous_user_id}`);
      this.eventSource2.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data)
        if(data.type=='ping'){
          return
        }
        if(data.type=='open'){
          this.listNew=JSON.parse(JSON.stringify(this.list))
          this.chat_list.push(this.listNew)
        }
        let LastItem=this.chat_list[this.chat_list.length - 1]
        if(data.type=='end'){
          // 结束重新开启话题
          return
        }else{
          LastItem.text.push(data)
          LastItem.id=data.id
          LastItem.position=data.type
        }
        LastItem.content=LastItem.text.map(item => item.message).join('');
        console.log(this.chat_list,'..this.chat_list')
      };
     
    },

    // 关闭sse
    sendEnd(id) {
      this.chat_list.forEach(val => {
        if (val.id == id) {
          val.ai_answer[0].isLoading=false
        }
      })
      this.isDisable=false
      this.eventSource1.close();
    }
  },
  
  
  onBeforeUnmount() {
    console.log('离开')
    // clearInterval(this.time)
  },

}
</script>

<style>
body {
  background: #f5f5f5;
}

.content-list {
  background-color: #fff;
  padding: 10px 0 20px;
}

.chat-box {

  border-radius: 10px;
  padding: 10px;
  margin: 10px 10px 20px;

}

.chat-left {
  background: #EFEFEF;
}

.chat-right {
  background: #c9e7ff;
}

.chat-box-item {
  vertical-align: center;
  line-height: 30px;
  background-color: #EFEFEF;
  border: 1px solid #EFEFEF;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.chat-box .chat-box-left {
  text-align: left;

}

.chat-box .chat-box-left .content {
  padding-right: 40%;
  box-sizing: border-box;
}

.chat-box .chat-box-right {
  text-align: left;
  /* display: flex; */
  /* align-items: center; */
  /* align-items: flex-end; */

  box-sizing: border-box;
}
.Aichat-box-right{
  padding-left: 33% !important;
  justify-content: space-between;
}
.chat-box-rightAi{
  display: flex;
  align-items: baseline !important;
}
.content-right{
width: calc(100vw - 130px);
  /* padding-left: 33% !important; */
}
.Aioperate{
  text-align: left !important;
  padding: 10px 0 0 10px;
  flex-shrink: 0;
}
.deepThinking{
  color: #333 !important;
  font-size: 12px;
  border: 1px solid #333;
  padding:0 5px;
  border-radius: 15px;
  text-align: center;
  box-sizing: border-box;
}

.chat-box .avatar {
  width: 30px;
  height: 30px;
}

.chat-box .content {
  padding: 10px;
  /*background-color: #EFEFEF;*/
}

.chat-box .operate {
  text-align: right;
  color: #1989fa;
}

.chat-box .send {
  margin: 10px 20px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #CCCCCC;
  cursor: pointer;
}

.memeberRecord_index {
  /*position: sticky;*/
  /*top: 0;*/
  z-index: 100;
  padding-top: 10px;
  background-color: #FFFFFF;
}

.public_visit_bottom {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.van-tabs__line {
  background-color: #389EEE !important;
}

.public_visit {
  padding: 0 0 20px;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 100;
}



.public_index {
  padding: 10px;
}


.van-tab {
  /*font-size: 26px;*/
  line-height: 76px;
}

.van-tabs--line .van-tabs__wrap {
  height: 80px;
}

.showPut {
  width: 600px;
  padding: 30px 0;
}

.showPut_title {
  border-bottom: 1px solid #F6F6F6;
  padding: 10px 30px 30px;
  max-height: 220px;
  overflow-y: auto;
}

.showPut_title>div {
  display: inline-block;
  background: #F4F4F4;
  /*font-size: 28px;*/
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding: 10px 15px;
  margin: 0 20px 20px 0;
  border-radius: 10px;
}

.showPut_title_active {
  background: #DFF2FF !important;
  color: #1F97EE !important;
}

.van-popup {
  border-radius: 12px;
}

.showPut textarea {
  width: 90%;
  height: 150px;
  background-color: #F6F6F6;
  /*font-size: 26px;*/
  padding: 10px;
  box-sizing: border-box;
  margin: 30px auto;
}

.showPut_content {
  padding: 0 30px;
}

.showPut_button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  cursor: pointer;
}

.showPut_button div {
  color: #ffffff;
  /*font-size: 26px;*/
  background-color: #389EEE;
  padding: 10px 10px;
  width: 170px;
  border-radius: 10px;
  text-align: center;
}

.showPut_button div:first-child {
  background-color: #389EEE;
}



.myMember_source_tab {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px 10px 15px 10px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tab_top {
  border-top: 1px solid #F5F5F5;
  margin-bottom: 10px;
  justify-content: start;
}

.tab_top>div {
  flex: none !important;
  margin-right: 20px;
}

.tabActive {
  color: #1F97EE !important;
  position: relative;
}

.tabActive::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 12px;
  background: #1F97EE;
}

.myMember_source_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  /* width: 16%; */
  flex: 1;
  text-align: center;
}

/* 基本信息 ----------------------------------------*/
.navTab_one {
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
}

.name_icon {
  background: linear-gradient(#3FA6F0, #7BC0F4);
  color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

.navTab_one_list {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 28px;*/
  border-bottom: 1px solid #F5F5F5;
  padding: 10px;
}

.navTab_one_list>div:first-child {
  width: 100px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 28px;*/
}

.navTab_one_list>div:last-child {
  flex: 1;
}

/* 基本信息----------------------- */

/* 报名记录-------------------------- */
.navTab_two {
  padding: 10px 10px;
  background: #ffffff;
  margin-top: 10px;
}

.navTab_two_tab {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 10px;
}

.navTab_two_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

.navTab_two_list {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #F5F5F5;
  padding: 10px 0;
}

.navTab_two_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

/* 报名记录----------------------------------------------------------- */

/* 在离职记录-------------------------------------------------------------- */
.navTab_three_list {
  background: #ffffff;
  padding: 10px;
  line-height: 58px;
  margin-bottom: 10px;
}

.navTab_three_list_flex {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.navTab_three_list_flex>div:first-child {
  width: 180px;
}

.navTab_three_list_flex>div:last-child {
  flex: 1;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
}

/* 在离职记录-------------------------------------------------------------- */


/* 打卡记录-------------------------------------------------------------- */
.navTab_four {
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
}

.navTab_four_tab {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 10px;
  width: 100%;
}

.navTab_four_tab div {
  /*border: 1px solid red;*/
}

.navTab_four_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

.navTab_four_list {
  display: flex;
  /*align-items: center;*/
  /*justify-content: space-between;*/
  padding: 10px 0;
  border-bottom: 1px solid #F5F5F5;
  margin: 0;
  /*border: 1px solid blue;*/
  text-align: center;
}

.navTab_four_list .empty_cell {
  display: block;
  visibility: hidden;
}

.navTab_four_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
  width: 21%;
}

.navTab_four_list_day span {
  display: inline-block;
  background: #E9F5FF;
  padding: 2px;
  border-radius: 3px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #1F97EE;
  font-weight: 400;
}

.navTab_four_list_day_night span {
  color: #999999;
}

.navTab_four_list_status span {
  background: #E9F5FF;
  padding: 2px;
  border-radius: 3px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F97EE;
}

.navTab_four_list_status_error span {
  color: #FF4E3A;
}

/* 打卡记录------------------------------------------------------- */

/* 回访记录------------------------------------------------------- */
.navTab_five_tab {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  justify-content: space-around;
}

.navTab_five_tab>div {
  background: #F5F5F5;
  /*font-size: 30px;*/
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  width: 200px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 20px;
}

.historyActive {
  background: #E9F5FF !important;
  color: #1F97EE !important;
}

.public_visit_flex {
  display: flex;
  align-items: center;
  /*font-size: 30px;*/
  height: 60px;
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
}

.public_visit_div {
  text-align: center;
  /*font-size: 30px;*/
  background: #DCF0FF;
  color: #389EEE;
  padding: 8px 8px;
  border-radius: 20px;
  width: 70px;
  border: 1px solid #97CFF7;
}

.public_index_main {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 10px;
}

.public_index_main_list {
  display: flex;
  /*font-size: 28px;*/
  line-height: 30px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.public_index_main_list>div:first-child {
  width: 100px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.public_index_main_list>div:last-child {
  flex: 1;
}

.public_index_main_list_time {
  display: flex;
  /*font-size: 28px;*/
  /*line-height: 48px;*/
  color: #999999;
}

.public_index_main_list_time>div:first-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.public_index_main_list_width>div:first-child {
  margin-right: 20px;
}

/* 回访记录------------------------------------------------ */

/* 发薪记录------------------------------------------------ */
.navTab_six {
  margin-top: 10px;
}

.navTab_six_list {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
  padding: 10px;
  background: #ffffff;
  line-height: 30px;
  margin-bottom: 10px;
}

.navTab_six_list_flex>div:last-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
}

.navTab_six_list_flex {
  display: flex;
}

.navTab_six_list_flex>div:first-child {
  width: 100px;
}

.navTab_six_list_flex_title {
  flex: 1;
}

.navTab_six_list_flex_son {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.navTab_six_list_flex_son>div:last-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
  flex: 1;
  line-height: 16px;
}

/* 发薪记录------------------------------------------------ */


/* 工资条------------------------------------------------ */

.paySilp {
  width: 100%;
  position: relative;
}

.paySilp_name {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  white-space: nowrap;
}

.paySilp_price {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}

.paySilp_price div:first-child {
  font-size: 56px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
}

.paySilp_price div:last-child {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  opacity: .9;
  margin-top: 10px;
}

.paySilp_basic div:last-child {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: auto;
}

.month {
  text-align: center;
  font-size: 30px;
  margin: 20px 0;
}

.paySilp_day {
  width: 90%;
  margin: 10px auto;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 10px 10px;
  box-sizing: border-box;
}

.paySilp_day_title {
  /*font-size: 22px;*/
  font-family: PingFang SC;
  font-weight: 400;
  color: #7F7F7F;
  position: relative;
  width: 92px;
  margin-bottom: 10px;
}

.paySilp_day_title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 26px;
  height: 4px;
  background: #389EEE;
  border-radius: 4px;
}

.paySilp_day_list {
  display: flex;
  align-items: center;
  /*font-size: 22px;*/
  font-family: PingFang SC;
  font-weight: 400;
  color: #090909;
  padding: 10px 0;
  border-bottom: 1px solid #F2F2F2;
}

.paySilp_day_list:last-child {
  border-bottom: 0;
}

.paySilp_day_list div:last-child {
  /*font-size: 20px;*/
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: auto;
}

.payImg {
  text-align: center;
  font-size: 32px;
  background: #E0C169;
  height: 100%;
  padding-top: 150px;
  box-sizing: border-box;
  color: #FFFFFF;
}

.payImg image {
  width: 250px;
  height: 230px;
  display: block;
  margin: 0px auto 10px;
}

page,
.div {
  height: 100%;
}

.picker {
  font-size: 30px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picker_play {
  transform: rotate(90deg);
  margin-left: 5px;
}

/* 工资条------------------------------------------------ */



/* 银行卡信息------------------------------------------------ */
.navTab_seven {
  padding-top: 10px;
}

.navTab_seven_list {
  background: #ffffff;
  padding: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}

.navTab_seven_list_flex {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.navTab_seven_list_flex>div:first-child {
  width: 100px;
}

.navTab_seven_list_flex>div:last-child {
  flex: 1;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
}

/* 银行卡信息-------------------------------------------------------- */

/* 异常记录-------------------------------------------------------- */
.navTab_eight_flex {
  display: flex;
  align-items: center;
  /*font-size: 28px;*/
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
}

.navTab_eight_div {
  text-align: center;
  /*font-size: 28px;*/
  background: #E9F5FF;
  color: #389EEE;
  padding: 10px 10px;
  border-radius: 20px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.navTab_eight_div .van-icon-plus {
  margin-right: 10px;
}

.navTab_eight_title {
  display: flex;
  align-items: center;
}

.navTab_eight_status {
  margin-left: auto;
  color: #FF913D;
}

.navTab_eight_status_processed {
  color: #1F97EE;
}

.navTab_eight_over {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

/* 异常记录-------------------------------------------------------- */

/* 住宿记录-------------------------------------------------------- */

.navTab_nine {
  padding: 10px;
  background: #ffffff;
  margin-top: 10px;
}

.navTab_nine_tab {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 10px;
}

.navTab_nine_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

.navTab_nine_list {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_nine_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

/* 住宿记录-------------------------------------------------------- */

/* 订单查看记录-------------------------------------------------------- */
.navTab_ten {
  margin-top: 10px;
}

.navTab_ten_time {
  background: #ffffff;
  padding: 10px;
}

.data_week_btn_time {
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
  border-bottom: 1px solid #F5F5F5;
  padding-bottom: 0px;
}

.navTab_ten_main {
  background: #ffffff;
  padding: 10px;
  margin-top: 10px;
}

.navTab_ten_tab {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  padding-bottom: 10px;
}

.navTab_ten_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
}

.navTab_ten_list {
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_ten_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
}

.navTab_ten_time .index {
  background: #ffffff;
}

/* 订单查看记录-------------------------------------------------------- */
.memeberPhone {
  position: fixed;
  bottom: 300px;
  right: 30px;
  width: 100px;
  height: 100px;
  z-index: 100;
}

.memeberPhone image {
  width: 100%;
  height: 100%;
}

.showContent {
  padding: 10px;
  width: 400px;
  height: 300px;
}

.showContent_signItem {
  height: 500px;
  overflow-y: auto;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  line-height: 30px;
}

.showContent_time {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 16px;
  line-height: 30px;
}

.showContent_time>div>text {
  color: #333333;
}

.navTab_five_tab_choose {
  text-align: center;
  /*font-size: 30px;*/
  background: #ffece9;
  color: #FF4E3A;
  padding: 8px 8px;
  border-radius: 20px;
  margin-left: auto;
  width: 70px;
  border: 1px solid #FF4E3A;
  margin-right: 20px;
}

.showPut_no_title {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.showPut_no_tip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /*font-size: 24px;*/
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF4E3A;
  padding: 15px;
}

.showPut_no_tip>div {
  flex: 1;
}

.appletsContractFile {
  border: 1px solid #EFEFEF;
  border-radius: 2px;
  width: 50px;
  height: 50px;
  margin: auto 2px;
}

.line {
  border-top: 1px dotted #CCC;
  margin: 20px;
}

.moreNull {
  text-align: center;
  /*font-size: 30px;*/
  color: #999999;
  margin-top: 10px;
  margin-bottom: 20px;
}

.showpopup {
  padding: 10px;
  box-sizing: border-box;
  width: 340px;
}

.answer {
  background: #efefef;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px 10px 10px 0;
}

.onClose {
  max-height: 700px;
}

.answer .operate {
  text-align: right;
  color: #1989fa;
}

.answer .send {
  margin: 10px 20px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #CCCCCC;
  cursor: pointer;
}

.answerTop {
  line-height: 30px;
  margin-bottom: 10px;
}

.scroll-container {
  height: calc(100vh - 154px);
  overflow: auto;
}

.msgtime {
  background: #fff;
  margin-bottom: 2px;
  color: #9da0a3;
}

.msgtime-right {
  text-align: right;
}
.thinking{
  border-left: 1px solid #8b8b8b;
  color: #8b8b8b;
  padding-left: 10px;
  box-sizing: border-box;
}
.AIAssistant{
    height: 60vh;
    overflow: scroll;
  }
.AIstyle{
  height: 40vh;
  overflow: scroll;
}
.operateRight{
  flex-shrink: 0;
}
.editImg{
width: 15px;
height: 15px;
margin-right: 10px;
margin-top:-10px
}
.editImg img{
width: 100%;
height: 100%;
}

</style>
